enum ClientEvent {
  INITIATIVE_PRODUCT_PROFILE_MAGIC_QUADRANT_LINK_CLICKED = "INITIATIVE_PRODUCT_PROFILE_MAGIC_QUADRANT_LINK_CLICKED",
  INITIATIVE_PRODUCT_PROFILE_MORE_FROM_GARTNER_LINK_CLICKED = "INITIATIVE_PRODUCT_PROFILE_MORE_FROM_GARTNER_LINK_CLICKED",
  INITIATIVE_PRODUCT_PROFILE_REVIEWS_LINK_CLICKED = "INITIATIVE_PRODUCT_PROFILE_REVIEWS_LINK_CLICKED",
  INITIATIVE_PRODUCT_PROFILE_WEBSITE_LINK_CLICKED = "INITIATIVE_PRODUCT_PROFILE_WEBSITE_LINK_CLICKED",
  PRODUCT_PROFILE_MAGIC_QUADRANT_LINK_CLICKED = "PRODUCT_PROFILE_MAGIC_QUADRANT_LINK_CLICKED",
  PRODUCT_PROFILE_MORE_FROM_GARTNER_LINK_CLICKED = "PRODUCT_PROFILE_MORE_FROM_GARTNER_LINK_CLICKED",
  PRODUCT_PROFILE_REVIEWS_LINK_CLICKED = "PRODUCT_PROFILE_REVIEWS_LINK_CLICKED",
  MARKET_PRODUCT_PROFILE_WEBSITE_LINK_CLICKED = "MARKET_PRODUCT_PROFILE_WEBSITE_LINK_CLICKED",
  PRODUCT_PROFILE_POWERED_BY_CRITICAL_CAPABILITIES_LINK_CLICKED = "PRODUCT_PROFILE_POWERED_BY_CRITICAL_CAPABILITIES_LINK_CLICKED",
  PRODUCT_PROFILE_POWERED_BY_PEER_INSIGHTS_LINK_CLICKED = "PRODUCT_PROFILE_POWERED_BY_PEER_INSIGHTS_LINK_CLICKED",
  INITIATIVE_PRODUCT_PROFILE_POWERED_BY_PEER_INSIGHTS_LINK_CLICKED = "INITIATIVE_PRODUCT_PROFILE_POWERED_BY_PEER_INSIGHTS_LINK_CLICKED",
  INITIATIVE_PRODUCT_PROFILE_POWERED_BY_CRITICAL_CAPABILITIES_LINK_CLICKED = "INITIATIVE_PRODUCT_PROFILE_POWERED_BY_CRITICAL_CAPABILITIES_LINK_CLICKED",
}

enum MqPosition {
  LEADER = "Leader",
  CHALLENGER = "Challenger",
  VISIONARY = "Visionary",
  NICHE_PLAYER = "Niche Player",
  NONE = "NONE",
}

export { ClientEvent, MqPosition };
