import React, { Fragment, useContext } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import {
  MQChallengersIcon,
  MQIcon,
  MQLeadersIcon,
  MQNichePlayersIcon,
  MQVisionariesIcon,
  SquareQuadrantIcon,
} from "gx-npm-icons";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TextLink,
  TypographyComponent,
} from "gx-npm-ui";
import { IconErrorData } from "../../ui/icons";
import noMagicQuadrant from "../../assets/images/no-magic-quadrant.svg";
import { magicQuadrantStyles as styles } from "./magic-quadrant.styles";
import { ProductOverviewContext } from "../../context/product-overview.context";
import { useCaptureEventsV2 } from "gx-npm-lib";
import { ClientEvent, MqPosition } from "../../app.constants";

import { colorPalette } from "gx-npm-common-styles";
import { GCOM_4177__newMQForVendorProfile } from "../../lib/feature-flags";

const useStyles = makeStyles(() => styles);

type QDPositionText = {
  [key: string]: string;
};

const MagicQuadrant: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { hasMq, mq, initId, initProdId, isError, isFromProductProfile, productId, productName } =
    useContext(ProductOverviewContext);
  const captureEvents = useCaptureEventsV2();
  const { qdPosition } = mq;
  const qdPositions = ["Challenger", "Leader", "Niche Player", "Visionary"];
  const qdPositionText: QDPositionText = {
    Challenger: t("Challengers"),
    Leader: t("Leaders"),
    "Niche Player": t("Niche players"),
    Visionary: t("Visionaries"),
  };

  const renderDocURL = (docURL: string) => {
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      let eventType, metaData;
      if (isFromProductProfile) {
        eventType = ClientEvent.INITIATIVE_PRODUCT_PROFILE_MAGIC_QUADRANT_LINK_CLICKED;
        metaData = { initiativeId: initId, initProductId: initProdId, productName };
      } else {
        eventType = ClientEvent.PRODUCT_PROFILE_MAGIC_QUADRANT_LINK_CLICKED;
        metaData = { productId: productId.toString(), productName };
      }
      captureEvents([{ eventType, metaData }]);
      window.open(docURL, "_blank", "noopener");
    };

    return (
      docURL !== null && (
        <TextLink
          href={docURL}
          onClick={handleClick}
          target={"_blank"}
          text={t("Learn more about this Magic Quadrant")}
          variant="secondary"
        />
      )
    );
  };

  const renderNoMagicQuadrant = () => {
    return (
      <div className={classes.noMq}>
        <img src={noMagicQuadrant} alt={t("no magic quadrant")} />
        <TypographyComponent styling={"p3"}>
          {t("This vendor is not included in a Magic Quadrant.")}
        </TypographyComponent>
      </div>
    );
  };

  const renderData = () => {
    if (isError) {
      return (
        <div className={classes.errorState}>
          <IconErrorData />

          <TypographyComponent styling={"p3"}>
            {t("Oops... we were unable to load this information.")}
          </TypographyComponent>
        </div>
      );
    }

    const { qdDesc, docURL } = mq;

    if (!hasMq || qdPosition === "NONE" || !docURL || docURL.slice(-2) === "-1") {
      return <Fragment>{renderNoMagicQuadrant()}</Fragment>;
    }

    return (
      <>
        <div className={classes.qdInfo}>
          <div className={classes.qdType}>
            <FeatureFlagBooleanContainer flagName={GCOM_4177__newMQForVendorProfile}>
              <FeatureFlagBooleanOn>
                <TypographyComponent styling="h5" boldness={"medium"} rootClassName={classes.qdText}>
                  {qdPosition === MqPosition.LEADER && <MQLeadersIcon />}
                  {qdPosition === MqPosition.CHALLENGER && <MQChallengersIcon />}
                  {qdPosition === MqPosition.VISIONARY && <MQVisionariesIcon />}
                  {qdPosition === MqPosition.NICHE_PLAYER && <MQNichePlayersIcon />}
                  {qdPositionText[qdPosition]}
                </TypographyComponent>
              </FeatureFlagBooleanOn>
              <FeatureFlagBooleanOff>
                <TypographyComponent styling="p4" rootClassName={classes.qdSubtitle}>
                  {t("Position")}
                </TypographyComponent>
                <TypographyComponent styling="h4" boldness={"medium"} rootClassName={classes.qdText}>
                  {qdPositionText[qdPosition]}
                </TypographyComponent>
              </FeatureFlagBooleanOff>
            </FeatureFlagBooleanContainer>
          </div>
          <FeatureFlagBooleanContainer flagName={GCOM_4177__newMQForVendorProfile}>
            <FeatureFlagBooleanOff>
              <div className={classNames(classes.qdContainer)}>
                {qdPositions.map((position) =>
                  position === qdPosition ? (
                    <div className={classes.qdBoxSelected} key={position}>
                      <TypographyComponent rootClassName={classes.positionTextSelected} styling="p5">
                        {qdPositionText[position]}
                      </TypographyComponent>
                    </div>
                  ) : (
                    <SquareQuadrantIcon
                      height={"76"}
                      width={"76"}
                      key={position}
                      letterSpacing="0.35px"
                      textFill={colorPalette.neutrals.coal.hex}
                      text={qdPositionText[position]}
                      fontSize="12px"
                      fontWeight="500"
                    />
                  )
                )}
              </div>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
        <TypographyComponent styling="p4" rootClassName={classes.qdDesc}>
          {qdDesc}
        </TypographyComponent>
        {renderDocURL(docURL)}
      </>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <FeatureFlagBooleanContainer flagName={GCOM_4177__newMQForVendorProfile}>
          <FeatureFlagBooleanOff>
            <MQIcon />
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
        <FeatureFlagBooleanContainer flagName={GCOM_4177__newMQForVendorProfile}>
          <FeatureFlagBooleanOn>
            <TypographyComponent styling="p2" boldness="medium" color="carbon">
              {t("Gartner Magic Quadrant")}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <TypographyComponent rootClassName={classes.title} styling="p2" boldness="medium" color="carbon">
              {t("Gartner Magic Quadrant")}
            </TypographyComponent>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      </div>
      {renderData()}
    </div>
  );
};
export default MagicQuadrant;
