import { colorPalette, weightPalette } from "gx-npm-common-styles";

const bannerClasses = {
  actionButton: {
    display: "inline-block",
  },
  actionCursor: {
    cursor: "pointer",
  },
  closeButton: {
    cursor: "pointer",
    display: "inline-block",
    float: "right",
    height: "14px",
    margin: "29px 21px",
  },
  container: {
    borderTopStyle: "solid",
    borderTopWidth: "2px",
    boxShadow: "0px 3px 3px 1px rgba(20, 19, 18, 0.1)",
    borderBottomRightRadius: "8px",
    borderBottomLeftRadius: "8px",
    display: "inline-block",
    minHeight: "72px",
    minWidth: "720px",
    textAlign: "left",
    width: "60%",
    "&$errorContainer": {
      backgroundColor: colorPalette.status.lightCherry.hex,
      borderTopColor: colorPalette.status.cherry.hex,
    },
    "&$successContainer": {
      backgroundColor: colorPalette.status.darkPear.hex,
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
      borderTopStyle: "none",
      boxShadow: "0px 8px 8px rgba(20, 19, 18, 0.16)",
      display: "flex",
      maxHeight: "72px",
      maxWidth: "602px",
      minHeight: "unset",
      minWidth: "320px",
      width: "unset",
      "&$containerWithBtn": {
        maxWidth: "678px",
        minWidth: "396px",
        "& $messageContainer": {
          marginRight: "12px",
        },
      },
      "&.mini": {
        backgroundColor: colorPalette.status.lightPear.hex,
        boxShadow: "none",
        minWidth: "fit-content",
        padding: "14px 52px 14px 18px",
        alignItems: "center",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        "& $messageContainer": {
          color: colorPalette.status.darkPear.hex,
          fontSize: "14px",
          lineHeight: "21px",
          margin: 0,
        },
        "& $iconContainer": {
          margin: "0 10px 0 0",
        },
      },
    },
    "&$warningContainer": {
      backgroundColor: colorPalette.status.lightLemon.hex,
      borderTopColor: colorPalette.status.lemon.hex,
    },
  },
  containerWithBtn: {
    // KEEP - referenced in container&$successContainer class
  },
  iconContainer: {
    display: "inline-block",
    margin: "26px 18px",
    position: "absolute",
  },
  messageContainer: {
    display: "inline-block",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    margin: "24px 0",
    marginLeft: "56px",
    maxWidth: "564px",
    top: 0,
  },
  errorContainer: {
    "& $iconContainer": {
      height: "20px",
    },
    "& $messageContainer": {
      color: colorPalette.status.poisonCherry.hex,
    },
  },
  successContainer: {
    "& $actionButton": {
      color: colorPalette.basic.white.hex,
      fontSize: 15,
      fontWeight: weightPalette.bold.amount,
      letterSpacing: 0.75,
      marginLeft: "auto",
      padding: "0 24px",
      alignSelf: "center",
    },
    "& $iconContainer": {
      height: "18px",
      margin: "14px",
      position: "unset",
    },
    "& $messageContainer": {
      color: colorPalette.status.lightPear.hex,
      margin: "12px 32px 12px 0px",
      maxWidth: "unset",
    },
  },
  warningContainer: {
    "& $iconContainer": {
      height: "18px",
    },
    "& $messageContainer": {
      color: colorPalette.status.darkLemon.hex,
    },
  },
};

export { bannerClasses };
