import { colorPalette } from "gx-npm-common-styles";

const textLinkStyles = {
  textLink: {
    cursor: "pointer",
    display: "inline",
    outline: "0",
    "& p": {
      display: "inline",
    },
    "&.primary": {
      borderBottom: `1px solid ${colorPalette.interactions.defaultCta.hex}`,
      padding: "2px 1px 0 1px",
      "& p": {
        color: colorPalette.interactions.defaultCta.hex,
        lineHeight: "20.5px",
      },
    },
    "&.secondary": {
      padding: "2px 8px 4px 1px",
      "& p": {
        color: colorPalette.neutrals.carbon.hex,
        marginRight: 9,
      },
      "& svg": {
        marginBottom: "-2px",
      },
    },
    "&.tertiary": {
      borderBottom: `1px solid ${colorPalette.neutrals.coal.hex}`,
      padding: "2px 1px 0 1px",
      "& p": {
        color: colorPalette.neutrals.coal.hex,
        lineHeight: "21px",
      },
    },
    "&.dark": {
      padding: "2px 1px 0 1px",
      "& p": {
        color: colorPalette.basic.white.hex,
        borderBottom: `1px solid ${colorPalette.basic.white.hex}`,
      },
    },
    "&:hover, &:focus": {
      "&.primary, &.secondary, &.tertiary": {
        backgroundColor: colorPalette.interactions.mildBerry.hex,
        textDecoration: "none",
      },
      "&.primary": {
        borderBottom: `1px solid ${colorPalette.interactions.hoverCta.hex}`,
        "& p": {
          color: colorPalette.interactions.hoverCta.hex,
        },
      },
      "&.tertiary": {
        borderBottom: `1px solid ${colorPalette.neutrals.coal.hex}`,
        "& p": {
          color: colorPalette.neutrals.coal.hex,
        },
      },
      "&.dark": {
        textDecoration: "none",
        "& p": {
          color: colorPalette.basic.white.hex,
        },
      },
    },
    "&.disabled": {
      "&.primary, &.tertiary": {
        borderBottom: `1px dashed ${colorPalette.neutrals.iron.hex}`,
        display: "inline",
        pointerEvents: "none",
        textDecoration: "none",
        "& p": {
          color: colorPalette.neutrals.iron.hex,
        },
      },
      "&.secondary": {
        borderBottom: "none",
        "& p": {
          color: colorPalette.neutrals.iron.hex,
        },
        "& svg path": {
          fill: colorPalette.neutrals.stone.hex,
        },
      },
    },
  },
  textLinkGCOM3726: {
    "&.secondary p": {
      border: "1px solid transparent",
    },
    "&:hover, &:focus": {
      "&.primary, &.secondary": {
        backgroundColor: "transparent",
      },
      "&.secondary p": {
        borderBottom: `1px solid ${colorPalette.neutrals.carbon.hex}`,
      },
    },
  },
};

export { textLinkStyles };
